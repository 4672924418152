import req from "../methods/req"
import method from '../methods/method'

// 新客户挖掘统计数据
export const bigDataIndex = function (data){
    return method.get_list({
        url:'/manage/crm.bigdata/index',
        ...data
    })
}

// 新客户挖掘统计数据
export const infoList = function (data){
    return method.get_list({
        url:'/manage/crm.bigdata/infoList',
        ...data
    })
}

// 新客户挖掘设置无效
export const changeStatus = function (data){
    return req({
        url:'/manage/crm.bigdata/changeStatus',
        ...data
    })
}

// 新客户挖掘指向用户
export const setUser = function (data){
    return req({
        url:'/manage/crm.bigdata/setUser',
        ...data
    })
}

export const getScreen = function (data){
    return req({
        url:'/manage/crm.bigdata/getScreen',
        ...data
    })
}
