<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['provice','city','district']"
            ref="list"
            :rowKey="'id'"
            @list_after="list_after"
        >
            <div
                v-for="item in ['provice','city','district']"
                :slot="item"
                :key="item"
                slot-scope="data"
            >
                <a href="javascript:;" @click="to_details(data.record,item)">{{data.text}}</a>
            </div>

            <template slot="status" slot-scope="data">
                <a-tag color="#108ee9">
                    <span
                        v-for="item in status_list"
                        :key="item.key"
                        v-show="item.key == data.text"
                    >{{item.value}}</span>
                </a-tag>
            </template>
        </TableList>
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { bigDataIndex } from "@/api/bigdata";

const columns = [

    {
        title: "总数",
        dataIndex: "total_num"
    },
    {
        title: "休闲娱乐记录数",
        dataIndex: "recreation_num"
    },
    {
        title: "公司企业记录数",
        dataIndex: "company_num"
    },
    {
        title: "医疗记录数",
        dataIndex: "medical_num"
    },
    {
        title: "政府机构记录数",
        dataIndex: "government_num"
    },
    {
        title: "教育培训记录数",
        dataIndex: "education_num",
    },
    {
        title: "文化传媒记录数",
        dataIndex: "media_num"
    },
    {
        title: "旅游景点记录数",
        dataIndex: "travel_num"
    },
    {
        title: "美食记录数",
        dataIndex: "food_num",
    },
    {
        title: "购物记录数",
        dataIndex: "shopping_num",
    },
    {
        title: "运动健身记录数",
        dataIndex: "exercise_num",
    },
    {
        title: "酒店记录数",
        dataIndex: "hotel_num",
    }
];
export default {
    name: "Index",
    components: {
        TableList,
    },
    data() {
        return {
            get_table_list: bigDataIndex,
            columns,
            type: '',
        };
    },

    computed: {

    },
    async created() {

    },
    methods: {
        to_details(record, type) {
			this.$refs.list.set_data('list', []);
			let data = {};
            if (type == 'city') {
                data = {
                    pid: this.type == 'city' ? record.id : record.pid,
                    type: 'city',
                }
            } else if (type == 'provice') {
                data = {
                    pid: this.type == 'provice' ? record.id : record.pid,
                    type: 'provice',
                }
            }else{
				this.$router.push({
					path:'/customer/excavate_statistics_details',
					query:{
						provice:record.provice,
						city:record.city,
						district:record.district,
					}
				})
			}
			this.$refs.list.pagination.page=1
            this.$refs.list.set_data('fixed_seo_data', data)
            this.$refs.list.get_list();
        },
        list_after({ res }) {
            let type = res.data.type

            this.type = type;

            this.columns = [...columns];

            if (type == 'district') {
                this.columns.unshift({
                    title: "区/县",
                    dataIndex: "district",
                    scopedSlots: {
                        customRender: "district"
                    }
                })

                this.columns.unshift({
                    title: "市",
                    dataIndex: "city",
                    scopedSlots: {
                        customRender: "city"
                    }
                })
            }

            if (type == 'city') {
                this.columns.unshift({
                    title: "市",
                    dataIndex: "city",
                    scopedSlots: {
                        customRender: "city"
                    }
                })
            }

            this.columns.unshift({
                title: "省",
                dataIndex: "provice",
                scopedSlots: {
                    customRender: "provice"
                }
            })

        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>